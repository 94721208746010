import { Link } from 'gatsby';
import React from 'react';

import logoPng from '../img/logo.png';

function NotFoundPage(): JSX.Element {
  return (
    <section className="py-8 py-md-10">
      <div className="container">

        <div className="row">
          <div className="col-12">
            <Link to="/">
              <img src={logoPng} alt="CAD Exchanger" title="CAD Exchanger" />
            </Link>
            <p className="mt-5">Oooops, seems like something went wrong...</p>
            <Link to="/" className="text-decoration-none">Home</Link>
          </div>
        </div>

      </div>
    </section>
  );
}

export default NotFoundPage;
